.wrapper {
  margin-left: auto;
  z-index: 1;
}

.leftAlign {
  margin-left: 0;
  margin-right: auto;
}

.styledButton {
  align-items: center;
  background-color: var(--st-color-grey-extra-light);
  border-radius: 50%;
  display: flex;
  height: var(--st-sizing-lg);
  justify-content: center;
  width: var(--st-sizing-lg);
}

.styledIcon {
  color: var(--st-color-blue);
}
