.productName {
  color: var(--ds-color-text-static-dark);
  margin: 0 0 var(--ds-spacing-3xs);
}

.qty {
  color: var(--ds-color-text-subdued);
  margin: 0 0 var(--ds-spacing-3xs);
}

.image-container {
  width: var(--ds-spacing-6xl);
  height: var(--ds-spacing-6xl);
  padding: var(--ds-spacing-4xs);
  margin-right: var(--ds-spacing-2xs);
  background-color: (--ds-color-layer-default);
}

.product-image {
  height: 100%;
  width: 100%;
}

.productWrapper {
  display: flex;
  /* flex-column replaces flex rows after design update */
  flex-direction: column;
  gap: var(--ds-spacing-3xs);
  margin-bottom: var(--ds-spacing-xs);

  &:last-child {
    margin-bottom: 0;
  }
}

.productInfo {
  display: flex;
  flex: 0 0 60%;
}

.safetyInformation {
  flex-grow: 1;
  max-width: var(--ds-spacing-8xl);

  & > div {
    justify-content: space-between;
  }
}
