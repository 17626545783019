.productName {
  color: var(--ds-color-text-static-dark);
  margin: 0 0 var(--ds-spacing-3xs);
}

.qty {
  color: var(--ds-color-text-subdued);
  margin: 0;
}

.productWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-3xs);
  margin-bottom: var(--ds-spacing-xs);

  @media (--ds-breakpoint-sm) {
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.productInfo {
  display: flex;
  flex: 0 0 60%;
}

.safetyInformation {
  flex-grow: 1;
  max-width: var(--ds-spacing-8xl);

  & > div {
    justify-content: space-between;
    white-space: unset;
  }
}

.addToBagMultipleProducts {
  width: 100%;
}
