.maxWidthContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: var(--ds-breakpoint-xl);
  margin: 0 auto;
  padding: var(--ds-spacing-xs);

  @media (--ds-breakpoint-md) {
    align-items: center;
    min-height: max-content;
    flex-flow: row;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--ds-spacing-3xs) var(--ds-spacing-2xs);

  @media (--ds-breakpoint-md) {
    width: 50%;
  }
}

.errorWrapper {
  margin: var(--ds-spacing-xs) 0;

  @media (--ds-breakpoint-md) {
    margin: var(--ds-spacing-2xs) 0 var(--ds-spacing-5xs);
  }
}

.errorRef {
  margin-left: var(--ds-spacing-5xs);
}

.linkWrapper {
  margin: var(--ds-spacing-xs) 0;

  @media (--ds-breakpoint-md) {
    margin: var(--ds-spacing-2xs) 0;
  }
}

.image {
  margin: 0 auto;
  width: 50%;
}
