.container {
  /* As Skroll breakpoints are smaller this value has dropped from 584px to not overflow */
  --container-breakpoint-width: 580px;

  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: var(--sk-dialog-desktop-width-fullscreen);
  height: calc(
    var(--sk-dialog-desktop-height-fullscreen) - var(--ds-spacing-xl) -
      (var(--ds-border-width-thin) * 2)
  );

  @supports (-webkit-touch-callout: none) {
    overflow: visible;
    height: calc(100% + 1px);
  }

  @media (--ds-breakpoint-md) {
    width: var(--container-breakpoint-width);
    height: auto;
  }
}

.divider {
  height: 1px;
  margin: 0 var(--ds-ds-spacing-3xs);
  background-color: var(--ds-color-border-subdued);
}

.modal-body {
  margin-bottom: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-info-container {
  display: flex;
  flex-direction: column;
  padding: var(--ds-spacing-sm) 0;

  @media (--ds-breakpoint-sm) {
    justify-content: space-between;
    padding: var(--ds-spacing-sm) 0;
    flex-direction: row;
  }
}

.item-row {
  width: 100%;
  display: flex;
  &:last-child {
    margin-bottom: var(--ds-spacing-sm);
  }

  @media (--ds-breakpoint-sm) {
    margin-bottom: var(--ds-spacing-xs);
  }
}

.image-container {
  /* Smaller than before, 100px var doesn't exist in Skroll */
  width: var(--ds-spacing-6xl);
  height: var(--ds-spacing-6xl);
  /* border: var(--ds-color-border-subdued) 1px solid; */
  padding: var(--ds-spacing-4xs);
  margin-right: var(--ds-spacing-2xs);
  background-color: var(--ds-color-layer-default);
}

.image-container img {
  height: 90px;
}

.product-image {
  height: 100%;
  width: 100%;
}

.product-title-text {
  margin: 0 0 var(--ds-spacing-3xs) 0;
}

.styled-text {
  margin: 0 0 var(--ds-spacing-3xs) 0;
}

.actions-button-container {
  display: flex;
  gap: var(--ds-spacing-2xs);
  flex-direction: column;
  border-top: 1px solid var(--ds-color-border-subdued);
  padding-top: var(--ds-spacing-md);

  @media (--ds-breakpoint-md) {
    flex-direction: row;
  }

  @media (--ds-breakpoint-lg) {
    justify-content: space-between;
  }
}

.product-details-wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
