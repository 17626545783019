.extraLargeButton > button {
  height: 3.75rem !important;
  font-size: 1.125rem !important;
}

.extraLargeButton > button div,
.extraLargeButton > button svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}

.extraLargeButton div[role='progressbar'] {
  top: initial;
  left: initial;
}
