.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  word-break: break-word;
  text-align: left;
  flex-direction: row;
  color: var(--ds-color-neutral-gray-1300);
}

.safetyIcon {
  margin: 0 0 0 var(--ds-size-sm);
  width: var(--ds-size-3xl);
  height: var(--ds-size-3xl);
}
