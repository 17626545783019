.maxWidthContainer {
  width: 100%;
  max-width: var(--ds-breakpoint-xl);
  margin: 0 auto var(--ds-spacing-sm);
}

.contentWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: var(--ds-spacing-sm);
  padding-top: var(--ds-spacing-xs);
  background-repeat: no-repeat;

  @media (--ds-breakpoint-md) {
    display: block;
    padding: 0;
    padding-top: 50%;
  }
}

.errorImage {
  width: 45%;
  margin-top: var(--ds-spacing-xs);
  margin-bottom: -20%;

  @media (--ds-breakpoint-md) {
    position: absolute;
    top: calc(-1 * var(--ds-spacing-4xl));
    left: 0;
    margin: 0;
    margin-left: 5%;
  }
}

.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;

  @media (--ds-breakpoint-md) {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: var(--ds-spacing-xl);
    align-items: flex-end;
  }
}

.errorTitle {
  margin: 0;
}

.headline {
  line-height: 1;
  color: var(--ds-color-text-inverse);
  margin: var(--ds-spacing-sm) 0 var(--ds-spacing-sm) 0;

  @media (--ds-breakpoint-md) {
    margin: 0;
  }
}

.subheading {
  color: var(--ds-color-text-inverse);
  margin: 0 0 var(--ds-spacing-2xl) 0;

  @media (--ds-breakpoint-md) {
    margin: 0 0 var(--ds-spacing-5xl) 0;
    width: 62%;
    text-align: right;
  }
}

.bodyText {
  color: var(--ds-color-text-inverse);
  padding: 0 var(--ds-spacing-xl);
  margin: 0 0 var(--ds-spacing-sm) 0;

  @media (--ds-breakpoint-md) {
    /* Copied and pasted font size from Skroll ds-heading-lg due to server side rendering */
    font-size: clamp(1.28125rem, 0.367347vw + 1.19515rem, 1.5625rem);
    font-weight: 700;
    margin: 0 0 var(--ds-spacing-xl) 0;
    text-align: right;
    width: 53%;
    padding: 0;
  }

  @media (--ds-breakpoint-lg) {
    width: 56%;
  }
}

.navigationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--ds-breakpoint-md) {
    justify-content: flex-end;
    margin-top: 0;
  }
}
