.wrapper {
  width: 100%;
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}

.leftAlign {
  text-align: right;
}

.inline {
  display: flex;
  align-items: baseline;
}

.regularPrice {
  text-decoration: line-through;
}

.priceText {
  display: flex;
  white-space: nowrap;
}

.salePrice {
  color: var(--ds-color-core-red-900);
}

.insiderPrice {
  color: var(--ds-color-core-purple-900);
}

.priceInfo {
  padding-left: var(--ds-spacing-xs);
}

.priceInfo div[data-test='tooltip-child-container'] {
  text-decoration: underline;
}
