.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  word-break: break-word;
  white-space: nowrap;
  text-align: left;
  flex-direction: row;
  white-space: nowrap;
  flex-shrink: 0;
}

.safetyIcon {
  margin: 0 0 0 var(--ds-size-sm);
  width: var(--ds-size-3xl);
  height: var(--ds-size-3xl);
}
