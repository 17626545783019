.imageContainer {
  display: flex;
  align-items: center;
  width: var(--st-sizing-3xl);
  min-width: var(--st-sizing-3xl);
  height: var(--st-sizing-3xl);
  padding: var(--st-sizing-4xs);
  margin-right: var(--st-sizing-xxs);
  background-color: var(--st-color-white);
  overflow: hidden;
  border: var(--ds-border-width-xs) solid var(--st-color-grey-light);
}

.imageContainer img {
  height: 90px;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--st-color-grey-extra-light);
  padding: var(--st-sizing-ms);
  color: var(--ds-color-text-informative);
}

.titleContainer {
  display: flex;
  padding: var(--st-sizing-4xs) 0 var(--st-sizing-xs) 0;
  align-items: center;
}

.titleContainer > p {
  margin: 0;
}

.giftContainer {
  display: flex;
  border-top: var(--ds-border-width-xs) solid var(--st-color-grey-light);
  padding: var(--st-sizing-3xs);
  align-items: center;
}

.gwpIcon {
  margin: 0 var(--st-sizing-xxs) 0 0;
}
