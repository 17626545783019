.strikeThrough {
  text-decoration: line-through;
  margin-bottom: 0;
}

.insiderPriceContainer {
  display: flex;
  align-items: center;
  gap: var(--ds-spacing-4xs);
  flex-wrap: wrap;
}

.insiderPrice {
  color: var(--ds-color-core-purple-900);
  margin-right: var(--ds-spacing-4xs);
}

.insiderIconText {
  display: flex;
  align-items: center;
}

.insiderSubText {
  color: var(--ds-color-core-purple-900);
  white-space: nowrap;
}

.insiderDescription {
  color: var(--ds-color-text-strong);
  margin-bottom: var(--ds-spacing-3xs);
}

.price {
  ---price-margin-value: 0rem;
  margin: var(---price-margin-value);
}
